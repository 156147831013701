<template>
  <div v-if="visible == true">
    <CSidebar
      scrollable
      position="fixed"
      :unfoldable="sidebarUnfoldable"
      :visible="visible"
      :class="visible == true ? 'sidebar-rigth' : 'sidebar-rigth-hide hide'"
    >
      <div>
        <FormUser
          v-if="component == 'form-user'"
          @onChangeFormUser="onChangeFormUser"
        />

        <!-- Não será mais necessário importar o componente aqui 
        pois o slot sabe qual template é para ser renderizado no side bar
        utilizando o  <template v-slot:actualItemSidebar> -->
        <slot name="actualItemSidebar" />
      </div>
    </CSidebar>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  props: { visible: Boolean, component: String, item: Object, width: String },
  name: 'AppSidebar',
  components: {},
  setup() {
    const store = useStore()
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
    }
  },
  data() {
    return {
      width_model: this.width != null ? this.width : 15,
      modal_visible: this.visible,
    }
  },
  methods: {
    onChangeFormUser(item) {
      this.$emit('onChangeFormUser', item)
    },
  },
}
</script>
<style>
.sidebar-rigth {
  color: black;
  background-color: white;
  right: 0;
  min-width: 250px;
  width: v-bind(width_model + '%');
  left: unset !important;
  overflow-x: scroll;
}
.sidebar-rigth-hide {
  background-color: transparent;
}
</style>
