<template>
  <div>
    <VueMultiselect
      v-model="modelValue"
      :options="options"
      noOptionsText="Nenhum resultado encontrado!"
      noResultsText="Nenhum resultado encontrado!"
      placeholder=""
      :searchable="true"
      :label="slugDesc"
      :trackBy="slugDesc"
      :valueProp="slugId"
      :value="slugDesc"
      :disabled="disabled"
      @input="onChangeCombo"
      @search-change="searchList"
      :required="required"
      :loading="isLoading"
    >
    </VueMultiselect>
  </div>
</template>

<script>
import service from '@/services/BaseService'

export default {
  name: 'Multiselect',
  props: {
    slugId: { type: String },
    slugDesc: { type: String },
    endpoint: { type: String },
    required: { type: Boolean, default: false },
    model: { default: null },
    disabled: { type: Boolean, default: false },
    enabledId: { type: Boolean, default: false },
  },
  data() {
    return {
      modelValue: this.model,
      options: [],
      isLoading: false,
    }
  },
  created() {
    if (this.modelValue) {
      this.setCombo(this.modelValue)
    } else {
      this.getItens(this.modelValue)
    }
  },
  methods: {
    searchList(valueInput) {
      if (!this.model) {
        this.getItens(valueInput)
      } else {
        if (valueInput.length >= 1) {
          this.getItens(valueInput)
        }
      }
    },
    onChangeCombo(value) {
      this.$emit('onChangeCombo', value)
    },
    async setCombo(valueInput) {
      var endpoint = this.endpoint
      if (valueInput) {
        endpoint = endpoint + `&${this.slugId}=${valueInput}`
      }
      this.isLoading = true
      try {
        var http = new service(endpoint)
        await http
          .find()
          .then((resp) => {
            this.options = resp.data.data
            this.key++
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      } catch (error) {
        this.isLoading = false
      }
    },
    async getItens(valueInput) {
      var endpoint = this.endpoint
      if (valueInput) {
        endpoint = endpoint + `&${this.slugDesc}=${valueInput}`
      }
      this.isLoading = true
      try {
        var http = new service(endpoint)
        await http
          .find()
          .then((resp) => {
            this.options = resp.data.data
            if (this.enabledId) {
              // this.options.forEach((element) => {
              //   element[this.slugDesc] =
              //     element[this.slugId].toString() +
              //     ' - ' +
              //     element[this.slugDesc]
              // })
            }
            this.key++
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      } catch (error) {
        this.isLoading = false
      }
    },
  },
}
</script>
