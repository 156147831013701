<template>
  <b-modal
    centered
    :key="key"
    no-close-on-esc
    no-close-on-backdrop
    v-model="visible_reset_password"
    hide-footer
    hide-header
  >
  </b-modal>
  <CDropdown variant="input-group">
    <CDropdownToggle placement="bottom-end" :caret="false">
      {{ user ? user.login_user : '' }}

      <b-avatar variant="primary" size="30">
        <BootstrapIcon icon="person" color="white" />
      </b-avatar>
    </CDropdownToggle>
    <CDropdownMenu>
      <CDropdownHeader class="bg-light fw-semibold py-2">
        Conta
      </CDropdownHeader>
      <CDropdownItem @click="logout()" style="cursor: pointer">
        <CIcon icon="cil-lock-locked" /> Sair
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
import { useStore } from 'vuex'
import { computed } from 'vue'
import auth from '../services/auth'
export default {
  name: 'AppHeaderDropdownAccnt',
  components: {},
  setup() {
    const store = useStore()
    return {
      user: computed(() => store.state.user),
      avatar: avatar,
      itemsCount: 42,
    }
  },
  data() {
    return {
      key: 0,
      visible_reset_password: false,
    }
  },
  methods: {
    onChangeProfile() {
      this.visible_reset_password
        ? (this.visible_reset_password = false)
        : (this.visible_reset_password = true)
    },

    async logout() {
      this.$store.commit({
        type: 'setToken',
        value: null,
      })
      auth.logout()
    },
  },
}
</script>
