export default [
  {
    component: '',
    name: 'Dashboard',
    icon: 'cil-speedometer',
    items: [
      {
        component: 'CNavItem',
        name: 'Dashboard',
        to: '/dashboard',
      },
      {
        component: 'CNavItem',
        name: 'Dashboard Usuario',
        to: '/dashboard-usuario',
      },
    ],
  },
  {
    component: '',
    name: 'Agendamento',
    icon: 'cil-calendar',
    items: [
      {
        component: 'CNavItem',
        name: 'Listagem Agendamentos',
        to: '/agendamentos',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Matriz Responsab.',
    icon: 'cil-settings',
    items: [
      {
        component: 'CNavItem',
        name: 'Usuário Rumo',
        to: '/usuario',
      },
      {
        component: 'CNavItem',
        name: 'Unidade',
        to: '/unidade',
      },
      {
        component: 'CNavItem',
        name: 'Sistema',
        to: '/sistema',
      },
      {
        component: 'CNavItem',
        name: 'Modulo',
        to: '/modulo',
      },
      {
        component: 'CNavItem',
        name: 'Funcionalidade Sistema',
        to: '/funcsistema',
      },
      {
        component: 'CNavItem',
        name: 'Setor',
        to: '/setor',
      },
      {
        component: 'CNavItem',
        name: 'Cargo',
        to: '/cargo',
      },
      {
        component: 'CNavItem',
        name: 'Status',
        to: '/status',
      },
      {
        component: 'CNavItem',
        name: 'TipoUsuario',
        to: '/tipousuario',
      },
      {
        component: 'CNavItem',
        name: 'Email Func',
        to: '/email_funcionalidade',
      },
      {
        component: 'CNavItem',
        name: 'Email Dest',
        to: '/email_destinatario',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Cadastros Gerais',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Linha Base',
        to: '/linha-base',
      },
    ],
  },
  {
    component: '',
    name: 'Ratificação',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Processos',
        to: '/processos',
      },
      // {
      //   component: 'CNavItem',
      //   name: 'Listagem de Processos Por Status',
      //   to: '/list_cards',
      // },
    ],
  },
  {
    component: '',
    name: 'Desligamento',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Desligamento',
        to: '/desligamento',
      },
    ],
  },
  {
    component: '',
    name: 'Transferência',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Transferência',
        to: '/transferencia',
      },
      {
        component: 'CNavItem',
        name: 'Relatórios',
        to: '/transferencia-relatorios',
      },
    ],
  },

  {
    component: '',
    name: 'Revogação',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Revogação',
        to: '/revogacao-usuario',
      },
      {
        component: 'CNavItem',
        name: 'Revogações RPA',
        to: '/revogacoes-pendentes-rpa',
      },
    ],
  },
  {
    component: '',
    name: 'Email',
    icon: 'cil-envelope-open',
    items: [
      {
        component: 'CNavItem',
        name: 'Email',
        to: '/email',
      },
    ],
  },
  {
    component: '',
    name: 'Importações',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Usuário',
        to: '/importacaoUser',
      },
      {
        component: 'CNavItem',
        name: 'SIV',
        to: '/importacaoSIV',
      },
      {
        component: 'CNavItem',
        name: 'Translogic',
        to: '/importacaoTL',
      },
      {
        component: 'CNavItem',
        name: 'ORI',
        to: '/importacaoORI',
      },
      {
        component: 'CNavItem',
        name: 'BPM',
        to: '/importacaoBPM',
      },
      {
        component: 'CNavItem',
        name: 'ARIBA',
        to: '/importacaoARIBA',
      },
      {
        component: 'CNavItem',
        name: 'SAT',
        to: '/importacaoSAT',
      },
      {
        component: 'CNavItem',
        name: 'KMM-TOS',
        to: '/importacaoKMMTOS',
      },
      {
        component: 'CNavItem',
        name: 'KMM-GUM',
        to: '/importacaoKMMGUM',
      },
      {
        component: 'CNavItem',
        name: 'AD',
        to: '/importacaoAD',
      },
      {
        component: 'CNavItem',
        name: 'SAP-RH',
        to: '/importacaoSAPRH',
      },
    ],
  },
  {
    component: '',
    name: 'Logs',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Integrador',
        to: '/logsIntegrador',
      },
      {
        component: 'CNavItem',
        name: 'Sistemas',
        to: '/logSistemas',
      },

      {
        component: 'CNavItem',
        name: 'Transferidos',
        to: '/logsTransferidos',
      },
      {
        component: 'CNavItem',
        name: 'Desligados',
        to: '/logsDesligados',
      },
      {
        component: 'CNavItem',
        name: 'Importação',
        to: '/logsImportacao',
      },
      {
        component: 'CNavItem',
        name: 'Logs Revogação',
        to: '/logsRevogacao',
      },
    ],
  },

  {
    component: '',
    name: 'Acessos e Permissões',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Last Login',
        to: '/lastLogin',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Parâmetros',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'SLA',
        to: '/parametros-sla',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Controle Acesso',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Funcionalidade',
        to: '/funcionalidade',
      },
      {
        component: 'CNavItem',
        name: 'Perfil',
        to: '/perfil',
      },
      {
        component: 'CNavItem',
        name: 'Direitos',
        to: '/direitos',
      },
    ],
  },
  {
    component: '',
    name: 'Simulação',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Desligamento',
        to: '/simulacaodesligamento',
      },
      {
        component: 'CNavItem',
        name: 'Transferência',
        to: '/simulacaotransferencia',
      },
      {
        component: 'CNavItem',
        name: 'Teste SIV/TL',
        to: '/teste-siv-tl',
      },
    ],
  },
]
