<template>
  <div>
    <VueMultiselect
      mode="tags"
      v-model="modelValue"
      :options="options"
      noOptionsText="Nenhum resultado encontrado!"
      noResultsText="Nenhum resultado encontrado!"
      placeholder=""
      :searchable="true"
      :label="slugDesc"
      :trackBy="slugDesc"
      :valueProp="slugId"
      :value="slugDesc"
      :disabled="disabled"
      :realizaConsulta="realizaConsulta"
      @input="onChangeCombo"
      @search-change="searchList"
      :required="required"
      :loading="isLoading"
    >
    </VueMultiselect>
  </div>
</template>
<script>
import service from '@/services/BaseService'

export default {
  name: 'Multiselect',
  props: {
    slugId: { type: String },
    slugDesc: { type: String },
    endpoint: { type: String },
    required: { type: Boolean, default: false },
    model: { default: null },
    disabled: { type: Boolean, default: false },
    realizaConsulta: { type: Boolean, default: true },
    enabledId: { type: Boolean, default: false },
  },
  data() {
    return {
      modelValue: this.model,
      options: [],
      isLoading: false,
    }
  },
  created() {
    if (this.modelValue) {
      this.setCombo(this.modelValue)
    } else {
      this.getItens(this.modelValue)
    }
  },
  methods: {
    searchList(valueInput) {
      // if (valueInput.length >= 1) {
      this.getItens(valueInput)
      // }
    },
    onChangeCombo(value) {
      this.$emit('onChangeCombo', value)
    },
    async setCombo(valueInput) {
      var endpoint = this.endpoint
      if (valueInput) {
        endpoint = endpoint + `&in_${this.slugId}=${valueInput}`
      }
      this.isLoading = true
      try {
        var http = new service(endpoint)
        await http
          .find()
          .then((resp) => {
            this.options = resp.data.data
            this.key++
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      } catch (error) {
        this.isLoading = false
      }
    },
    async getItens(valueInput) {
      if (this.realizaConsulta == true) {
        var endpoint = this.endpoint
        if (valueInput) {
          endpoint = endpoint + `&${this.slugDesc}=${valueInput}`
        }
        this.isLoading = true
        try {
          var http = new service(endpoint)
          await http
            .find()
            .then((resp) => {
              this.options = resp.data.data
              if (this.enabledId) {
                // this.options.forEach((element) => {
                //   element[this.slugDesc] =
                //     element[this.slugId].toString() +
                //     ' - ' +
                //     element[this.slugDesc]
                // })
              }
              this.key++
              this.isLoading = false
            })
            .catch(() => {
              this.isLoading = false
            })
        } catch (error) {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
