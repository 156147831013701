<template>
  <CHeader position="sticky" class="mb-4">
    <screen-short
      v-if="screenshotStatus"
      @destroy-component="destroyComponent"
      @get-image-data="getImg"
    >
    </screen-short>
    <CContainer fluid>
      <CHeaderToggler
        class="ps-1 toggle-sidebar"
        @click="$store.commit('toggleSidebar')"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/"> </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <div class="mt-2 mr-2" style="font-size: 14px; color: grey">
          Versão server: {{ this.versao_server }} -
        </div>
        <div class="mt-2 mr-2" style="font-size: 14px; color: grey">
          Versão conector: {{ this.versao_conector }} -
        </div>
        <div class="mt-2 mr-2" style="font-size: 14px; color: grey">
          Ultima atualização dia 27-01-2025 às 18:00
        </div>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
      <AppHeaderDefault />
    </CContainer>
  </CHeader>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDefault from './AppHeaderDefault'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { ref } from 'vue'
import service from '@/services/BaseService'
import serviceConector from '@/services/BaseServiceConector'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDefault,
    AppHeaderDropdownAccnt,
  },
  data() {
    return {
      timerEnabled: true,
      timerCount: 0,
      versao_conector: '',
      versao_server: '',
    }
  },
  async created() {
    await this.getVersaoConector()
    await this.getVersaoServer()
  },
  setup() {
    const store = useStore()
    const screenshotStatus = ref(false)
    const destroyComponent = function (status) {
      screenshotStatus.value = status
    }

    return {
      user: computed(() => store.state.user),
      // logo,
      screenshotStatus,
      destroyComponent,
    }
  },
  methods: {
    async getVersaoConector() {
      try {
        var http = new serviceConector('conector/versao')
        await http
          .find()
          .then((resp) => {
            this.versao_conector = resp?.data?.message
          })
          .catch(() => {
            this.versao_conector = null
          })
      } catch (e) {
        this.versao_conector = e.message
      }
    },
    async getVersaoServer() {
      try {
        var http = new service('server/versao')
        await http
          .find()
          .then((resp) => {
            this.versao_server = resp?.data?.message
          })
          .catch(() => {
            this.versao_server = null
          })
      } catch (e) {
        this.versao_server = e.message
      }
    },
    print() {
      this.screenshotStatus = !this.screenshotStatus
    },
  },
}
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .toggle-sidebar {
    display: none;
  }
}
</style>
