<template>
  <Datepicker
    v-model="item"
    inline
    autoApply
    locale="pt-BR"
    cancelText="Cancelar"
    selectText="OK"
  />
</template>

<script>
import moment from 'moment'
export default {
  props: { date: String, model: String },
  data() {
    return {
      key: 0,
      item: this.date,
    }
  },
  created() {
    this.item != null
      ? (this.item = moment(this.item, 'DD-MM-YYYY').format('YYYY-MM-DD H:mm'))
      : (this.item = null)
  },
  watch: {
    item(value) {
      if (value != null) {
        var formattedDate = moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
        var resp = {
          model: this.model,
          value: formattedDate,
        }
        this.$emit('onChangeDatePicker', resp)
      } else {
        this.$emit('onChangeDatePicker')
      }
    },
  },
}
</script>
