<template>
  <div class="login-container">
    <div class="login-content">
      <img
        class="logo-login mt-3"
        src="../assets/logo-rumo.webp"
        alt="Logotipo da Microsoft"
      />
      <h1 class="title is-4 mb-5">Bem-vindo ao SGIS!</h1>
      <button
        class="azure-ad-button button is-fullwidth is-medium mt-5 mb-3"
        style="
          color: gray;
          box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
          width: 100%;
        "
        @click="doLogin"
      >
        ENTRAR COM
        <img
          src="@/assets/azure-ad-icon.png"
          alt="Azure AD Icon"
          class="azure-ad-icon"
        />
        <div style="color: #009fe4cc">AZURE AD</div>
      </button>
    </div>
  </div>
</template>

<script>
import auth from '../services/auth'
export default {
  async mounted() {
    // Handle the redirect response when the component mounts
    await auth.handleRedirectResponse().then((v) => {
      if (v == true) {
        this.$emit('loginComplete')
      }
    })
  },
  methods: {
    async doLogin() {
      try {
        await auth.login()
        // this.$emit('loginComplete')
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.logo-login {
  width: 200px; /* Largura ajustada */
  height: 50px; /* Altura ajustada */
  object-fit: contain; /* Garante que a imagem seja redimensionada proporcionalmente */
  margin-bottom: 20px; /* Espaçamento abaixo da logo */
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Largura total */
  height: 100vh; /* Altura total da tela */
  background-image: url('../assets/bg_login.png'); /* Imagem de fundo */
  background-size: cover; /* Ajusta a imagem para cobrir todo o fundo */
  background-position: center; /* Centraliza a imagem */
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
}

.login-content {
  height: auto;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%; /* Ajusta para ocupar 100% da largura */
  max-width: 400px; /* Define uma largura máxima para evitar que fique muito largo em telas grandes */
  margin: auto; /* Centraliza o conteúdo */
  align-content: center;
}

/* Estilo responsivo para telas menores */
@media (max-width: 768px) {
  .login-content {
    width: 100%; /* Mantém 100% de largura */
    padding: 16px; /* Ajusta o padding */
  }

  .logo-login {
    width: 150px; /* Ajusta o tamanho da logo para telas menores */
    height: 40px; /* Ajusta a altura da logo para telas menores */
    margin-bottom: 16px; /* Reduz o espaço abaixo da logo */
  }

  .title {
    font-size: 1.2rem; /* Ajusta o tamanho do título */
  }

  .subtitle {
    font-size: 1rem; /* Ajusta o tamanho do subtítulo */
  }
}

/* Estilo responsivo para telas ainda menores, como smartphones */
@media (max-width: 480px) {
  .login-content {
    width: 90%; /* Ajusta a largura para smartphones */
    padding: 12px; /* Reduz ainda mais o padding */
  }

  .logo-login {
    width: 120px; /* Ajusta o tamanho da logo para smartphones */
    height: 30px; /* Ajusta a altura da logo para smartphones */
  }

  .title {
    font-size: 1rem; /* Ajusta o tamanho do título */
  }

  .subtitle {
    font-size: 0.9rem; /* Ajusta o tamanho do subtítulo */
  }
}

.title {
  margin-bottom: 0.5rem;
  color: #003865;
}

.subtitle {
  margin-bottom: 1.5rem;
  color: #003865;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification {
  margin-top: 1rem;
}

.primary {
  background-color: #003865 !important;
  color: white;
}

.azure-ad-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.azure-ad-button:hover {
  background-color: #e1e1e1;
}

.azure-ad-icon {
  width: 36px;
  height: 36px;
  margin-left: 10px;
}
</style>
