<template>
  <div id="app">
    <router-view v-if="user" />
    <div v-if="error" class="notification is-danger is-4 title">
      {{ error }}
    </div>

    <Login v-if="!user && !error" @loginComplete="updateUser" />
  </div>
</template>

<script>
import auth from './services/auth'
import Login from './components/Login'

export default {
  name: 'App',

  components: { Login },

  data: function () {
    return {
      // Objeto da conta do usuário sincronizado com MSAL getAccount()
      user: {},
      // Token de acesso obtido via MSAL para chamar a API do Graph
      accessToken: '',

      // Detalhes obtidos da API do Graph, objeto do usuário e foto
      graphDetails: null,
      graphPhoto: null,

      // Alternadores de visibilidade para os três popups de detalhes
      showUserDetails: false,
      showGraphDetails: false,
      showTokenDetails: false,

      // Quaisquer erros
      error: '',
    }
  },

  watch: {
    user: async function () {
      try {
        await auth.acquireToken().then(async (tokenResp) => {
          this.$store.commit({
            type: 'setToken',
            value: tokenResp,
          })
        })
      } catch (err) {
        // Em caso de erro, limpar o token da store
        this.$store.commit({
          type: 'setToken',
          value: null,
        })
      }
    },
  },

  async created() {
    // Configuração básica do helper MSAL com o ID do cliente, ou desista
    if (process.env.VUE_APP_ROOT_AZURE_CLIENT_ID) {
      auth.configure(process.env.VUE_APP_ROOT_AZURE_CLIENT_ID, false)

      // Restaure qualquer usuário local armazenado ou em cache
      this.user = auth.user()
    } else {
      this.error =
        'VUE_APP_ROOT_AZURE_CLIENT_ID não está definido, o aplicativo não funcionará!'
    }
  },

  methods: {
    // Atualiza o usuário do MSAL
    updateUser() {
      this.user = auth.user()
    },

    // Remove detalhes do usuário armazenados localmente, é o mesmo que logout
    shallowLogout() {
      this.user = null
      this.graphDetails = null
      this.userDetails = null
      this.graphPhoto = null
      auth.clearLocal()
    },

    // Logout completo local e do servidor
    fullLogout() {
      auth.logout()
    },
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
.hero-body img {
  width: 80px;
  vertical-align: middle;
  padding-right: 1rem;
}

.hero-body {
  padding: 0.5rem;
}

.hero {
  margin-bottom: 1rem;
}

.graphphoto {
  border-radius: 15px;
}

.underline {
  border-bottom: 3px solid #bbb;
}

.gitlink,
.gitlink a:visited {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  color: #333436;
}
.cabecalho-login {
  background-image: linear-gradient(
    141deg,
    #003865 0%,
    #003864 71%,
    #003863 100%
  ) !important;
}
</style>
