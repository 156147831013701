<template>
  <div>
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div>
        <ConfirmDialog>
          <template #message="slotProps">
            <div class="flex p-4">
              <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
              <p class="pl-2">{{ slotProps.message.message }}</p>
            </div>
          </template></ConfirmDialog
        >
        <router-view :key="$route.fullPath" />
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'

export default {
  components: {
    AppFooter,
    AppHeader,
  },
}
</script>
