<template>
  <b-form-select
    class="form-control"
    v-model="model"
    :key="key"
    @change="onChangeCombo"
    :required="required"
  >
    <b-form-select-option
      v-for="obj in items"
      :key="obj"
      :value="obj[slugId]"
      >{{ obj[slugDesc] }}</b-form-select-option
    >
  </b-form-select>
</template>

<script>
import service from '@/services/BaseService'
export default {
  props: ['slug', 'slugId', 'slugDesc', 'endpoint', 'required'],
  data() {
    return {
      model: this.slug,
      key: 0,
      items: [],
    }
  },
  created() {
    this.getItens()
  },
  methods: {
    onChangeCombo(value) {
      this.$emit('onChangeCombo', value)
    },
    async getItens() {
      var http = new service(this.endpoint)
      await http
        .find()
        .then((resp) => {
          this.items = resp.data.data
          this.key++
        })
        .catch(() => {})
    },
  },
}
</script>
