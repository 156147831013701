<template>
  <b-button variant="light" @click="openFilter">
    <BootstrapIcon icon="filter" color="black" />
    Filtro
  </b-button>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'

export default {
  emits: ['openFilter'],
  data() {
    return {
      form: null,
      item: null,
    }
  },
  created() {
    //Escuta as ações disparadas pela tela de modulos
    //habilita as opções de ações
    this.emitter.on('modal-actions', (value) => {
      if (value) {
        this.form = value.form
        this.item = value.item
      } else {
        this.form = null
        this.item = null
      }
    })
  },
  setup() {
    const breadcrumbs = ref()
    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active: route.path === router.currentRoute.value.fullPath,
          name: route.name,
          hasActions: route.props.default.hasActions,
          path: `${router.options.history.base}${route.path}`,
        }
      })
    }
    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })
    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
  methods: {
    openFilter(event) {
      this.emitter.emit('modal-filter', event)
    },
  },
}
</script>
