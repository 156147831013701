<template>
  <CFooter>
    <div>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} </span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Distribuído por</span>
      <a href="https://33robotics.com/home.html">Rumo</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
