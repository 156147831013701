import axios from 'axios'
import store from '@/store'
import router from '@/router'
import VueSimpleAlert from 'vue3-simple-alert-next'
import main from '../main'
//import router from '../router'
/** @type {BaseService} */
export default class BaseService {
  constructor(api) {
    this.api = `${process.env.VUE_APP_ROOT_API}/${api}`
    if (store.state.token) {
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${store.state.token}`
    }
    this.axios = axios
    axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        switch (error.response.status) {
          case 403:
            router.push({
              name: 'Sem Permissão de Acesso',
              params: { error: error.response.data.message },
            })
            break
          case 404:
            if (error.response?.data?.message === 'Token Expirado') {
              main.config.globalProperties.emitter.emit('eventoTokenExpirado', {
                message: 'Token Expirado',
              })
            }

            break
          case 406:
            router.push('/notPermissionGestor')
            break
          // case 500:
          //   VueSimpleAlert.alert(
          //     '500 - Servidor indisponível, erro interno do servidor, entre em contato com o suporte.',
          //     'Atenção!',
          //     'warning',
          //     {
          //       animation: true,
          //     },
          //   )
          //   return Promise.resolve(error.response)
          case 502:
            VueSimpleAlert.alert(
              '502 - Servidor indisponível, erro interno do servidor, entre em contato com o suporte.',
              'Atenção!',
              'warning',
              {
                animation: true,
              },
            )
            return Promise.resolve(error.response)
          default:
            return Promise.reject(error)
        }
      },
    )
  }

  /**
   * Listar todos os itens
   */
  find(obj) {
    //
    if (obj) {
      const query = Object.keys(obj)
        .map((k) => `${k}=${encodeURIComponent(obj[k])}`)
        .join('&')
      return this.axios.get(`${this.api}?${query}`)
    } else {
      return this.axios.get(`${this.api}`)
    }
  }

  findRatificacao() {
    return this.axios.get(`${this.api}`)
  }

  /**
   * Custom Post
   */
  post(url, obj) {
    if (url != undefined) {
      return this.axios.post(`${this.api}/${url}`, obj)
    }
    return this.axios.post(`${this.api}`, obj)
  }

  /**
   * Listar todos os itens
   */
  params(obj) {
    const query = Object.keys(obj)
      .map(() => '${k}=${encodeURIComponent(obj[k])}')
      .join('&')
    return this.axios.get(`${this.api}/param?${query}`)
  }

  /**
   * Listar todos os itens
   */
  search(obj) {
    const query = Object.keys(obj)
      .map(() => '${k}=${encodeURIComponent(obj[k])}')
      .join('&')
    return this.axios.get(`${this.api}/search?${query}`)
  }

  /**
   *  Selecionar o equivalente ao id passado
   * @param {*} id
   */
  findOne(id) {
    return this.axios.get(`${this.api}/${id}`)
  }
  findFile(id) {
    return this.axios.get(`${this.api}/${id}`, {
      responseType: 'blob',
    })
  }

  /**
   *  Inserir um novo item
   * @param {*} data
   */
  create(obj) {
    return this.axios.post(`${this.api}`, obj)
  }

  alteraSystemService(obj) {
    return this.axios
      .post(`${this.api}`, obj)
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          return Promise.reject(response.data.message)
        }
      })
      .catch((error) => {
        if (error.response) {
          const { status, data } = error.response

          // Tratamento de erros específicos
          if (status === 404) {
            return Promise.reject(data.message)
          }
        } else {
          return Promise.reject(error.message)
        }
      })
  }

  /**
   *  Atualizar um item
   * @param {*} data
   */
  update(id, data) {
    return this.axios.put(`${this.api}/${id}`, data)
  }
  /**
   *  Atualizar um item por um endpoint distinto, sem a necessidade de passar um id.
   * @param {*} data
   */
  forceUpdate(data) {
    return this.axios.put(`${this.api}`, data)
  }

  /**
   * Remover um item
   * @param {*} id
   */
  delete(id) {
    return this.axios.delete(`${this.api}/${id}`)
  }
  /**
   *  Inserir um novo item type form-data
   * @param {*} data
   */
  createFormData(data) {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    return this.axios.post(`${this.api}`, data, config)
  }

  sendMail(payload) {
    var data = {
      body: {
        assunto: payload.assunto,
        corpo: payload.corpo ? payload.corpo : '',
        destinario: payload.email,
      },
      file: payload.file,
    }

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: this.api,
      data: data,
    }

    return this.axios(config)
  }

  async downloadZip(endpoint, nomeArquivo) {
    try {
      const response = await axios.get(`${this.api}${endpoint}`, {
        responseType: 'arraybuffer',
      })

      const blob = new Blob([response.data], { type: 'application/zip' })

      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = `${nomeArquivo}.zip`
      document.body.appendChild(link)
      link.click()

      // Limpeza de recursos
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
      return
    } catch (error) {
      console.error('Erro ao baixar o arquivo ZIP', error)
      this.$toast.error('Erro ao baixar o arquivo ZIP ' + error)
      return
    }
  }
}
