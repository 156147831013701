<template>
  <b-card class="mt-2">
    <template #header>
      Filtro
      <b-badge variant="success" class="mr-2">{{ labelHeader }}</b-badge>
    </template>
    <div>
      <b-form @submit="onSubmit" class="row" :key="key">
        <div v-for="input in fieldsInput" :key="input" :class="input.class">
          <b-form-group v-if="input.type == 'text'" :label="input.label">
            <b-form-input
              v-model="input.value"
              :required="input.required"
            ></b-form-input>
          </b-form-group>

          <b-form-group v-if="input.type == 'number'" :label="input.label">
            <b-form-input
              type="number"
              v-model="input.value"
              :required="input.required"
            ></b-form-input>
          </b-form-group>
          <b-form-group v-if="input.type == 'switch'" :label="input.label">
            <InputSwitch
              v-model="input.value"
              :trueValue="true"
              :falseValue="false"
            />
          </b-form-group>

          <b-form-group v-if="input.type == 'date'" :label="input.label">
            <PrimeCalendar
              style="width: 100%"
              v-model="input.value"
              selectionMode="single"
              dateFormat="dd/mm/yy"
              showIcon
            />
          </b-form-group>
          <b-form-group v-if="input.type == 'datetime'" :label="input.label">
            <PrimeCalendar
              style="width: 100%"
              v-model="input.value"
              selectionMode="single"
              dateFormat="dd/mm/yy"
              :showTime="true"
              hourFormat="24"
              showIcon
            />
          </b-form-group>
          <b-form-group v-if="input.type == 'combo'" :label="input.label">
            <SingleSelect
              :disabled="input.disabled ? input.disabled : false"
              :slug="input.slug"
              :slugId="input.slugId"
              :slugDesc="input.slugDesc"
              :endpoint="input.endpoint"
              :required="input.required"
              :model="input.value"
              @onChangeCombo="
                (value) => {
                  input.value = value
                }
              "
            />
          </b-form-group>
        </div>
        <b-row class="justify-content-end mt-2 mb-2">
          <b-button
            class="ml-2 col-lg-auto"
            @click="clearFilter"
            variant="info"
          >
            Limpar Filtros
          </b-button>
          <b-button class="ml-2 col-lg-auto" type="submit" variant="primary">
            Filtrar
          </b-button>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    fieldsInput: Array,
    table: String,
    labelHeader: String,
    item: Object,
  },
  data() {
    return {
      key: 0,
      form: this.item ?? {},
    }
  },
  created() {},
  methods: {
    setInputs() {
      this.form = {}
      this.fieldsInput.forEach((field) => {
        this.form[field.slug] = field.value
      })
    },
    async onSubmit(event) {
      this.setInputs()
      this.emitter.emit('update-filter', [event, this.form])
    },
    clearFilter() {
      this.fieldsInput.forEach((field) => {
        field.value = null
      })
      this.key++
    },
  },
}
</script>
