<template>
  <div class="text-center mb-5 mt-5">
    <img src="../assets/not-permission.png" height="100" />
    <div class="h5 mt-2">
      <div v-if="this.$route.params.error">
        <span>{{ this.$route.params.error }}</span>
      </div>
      <span v-else>Sem permissão de acesso!</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['error'],
}
</script>
