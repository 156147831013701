/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Dados que permanecem até ser atualizado
// Não estando no dataState a store é limpa após refresh
const dataState = createPersistedState({
  paths: ['user', 'token'],
})

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    user: {},
    token: null,
    pedidoAberto: {},
    direitosKeycloak: null,
    direitosOri: null,
    direitosSiv: null,
    direitosTranslogic: null,
    direitosSat: null,
    direitosAriba: null,
    direitosBpm: null,
    direitosKmmTos: null,
    direitosKmmGum: null,
    filterTransferencia: {},
  },
  plugins: [dataState],
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setUser(state, payload) {
      state.user = payload.value
    },
    setToken(state, payload) {
      state.token = payload.value
    },
    setDireitosKeycloak(state, payload) {
      //  state.direitosKeycloak = payload.value
    },
    setDireitosOri(state, payload) {
      //  state.direitosOri = payload.value
    },
    setDireitosSiv(state, payload) {
      //  state.direitosSiv = payload.value
    },
    setDireitosTranslogic(state, payload) {
      //  state.direitosTranslogic = payload.value
    },
    setDireitosSat(state, payload) {
      //  state.direitosSat = payload.value
    },
    setDireitosAriba(state, payload) {
      //  state.direitosAriba = payload.value
    },
    setDireitosBpm(state, payload) {
      // state.direitosBpm = payload.value
    },
    setDireitosKmmTos(state, payload) {
      //  state.direitosKmmTos = payload.value
    },
    setDireitosKmmGum(state, payload) {
      //  state.direitosKmmGum = payload.value
    },
    setFilterTransferencia(state, payload) {
      state.filterTransferencia = payload.value
    },
  },
  actions: {},
  modules: {},
})
