<template>
  <b-form @submit="onSubmit">
    <b-card class="mt-2">
      <template #header>
        <b-row class="text-end">
          <b-col class="text-start">
            {{ form.id == null ? 'Cadastro' : 'Alteração: ' + form.id }}
            <b-badge variant="success" class="mr-2">{{ labelHeader }}</b-badge>
          </b-col>
          <b-col>
            <b-button class="ml-2" type="submit" variant="primary">
              Salvar
            </b-button>
            <b-button
              v-if="positionModal == 'center' || positionModal == null"
              class="ml-2"
              variant="light"
              @click="onChangeCloseModal()"
              >X</b-button
            >
          </b-col>
        </b-row>
      </template>
      <div class="row">
        <div v-for="input in fieldsInput" :key="input" :class="input.class">
          <b-form-group v-if="input.type == 'text'" :label="input.label">
            <b-form-input
              :maxlength="input.maxlength ? input.maxLength : 80"
              :disabled="input.disabled ? input.disabled : false"
              v-model="input.value"
              :required="input.required"
            ></b-form-input>
          </b-form-group>
          <b-form-group v-if="input.type == 'email'" :label="input.label">
            <b-form-input
              type="email"
              :maxlength="input.maxlength ? input.maxLength : 80"
              :disabled="input.disabled ? input.disabled : false"
              v-model="input.value"
              :required="input.required"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group v-if="input.type == 'char'" :label="input.label">
            <b-form-input
              :disabled="input.disabled ? input.disabled : false"
              maxlength="1"
              v-model="input.value"
              :required="input.required"
            ></b-form-input>
          </b-form-group>

          <b-form-group v-if="input.type == 'number'" :label="input.label">
            <b-form-input
              :disabled="input.disabled ? input.disabled : false"
              type="number"
              v-model="input.value"
              :required="input.required"
            ></b-form-input>
          </b-form-group>
          <b-form-group v-if="input.type == 'switch'" :label="input.label">
            <InputSwitch
              :disabled="input.disabled ? input.disabled : false"
              v-model="input.value"
              trueValue="1"
              falseValue="0"
            />
          </b-form-group>

          <b-form-group v-if="input.type == 'date'" :label="input.label">
            <PrimeCalendar
              style="width: 100%"
              :disabled="input.disabled ? input.disabled : false"
              v-model="input.value"
              selectionMode="single"
              dateFormat="dd/mm/yy"
              showIcon
            />
          </b-form-group>
          <b-form-group v-if="input.type == 'datetime'" :label="input.label">
            <PrimeCalendar
              style="width: 100%"
              v-model="input.value"
              selectionMode="single"
              dateFormat="dd/mm/yy"
              :showTime="true"
              hourFormat="24"
              showIcon
            />
          </b-form-group>
          <b-form-group v-if="input.type == 'combo'" :label="input.label">
            <!-- <ComboDefault
              :slug="input.slug"
              :slugId="input.slugId"
              :slugDesc="input.slugDesc"
              :endpoint="input.endpoint"
              :required="input.required"
              @onChangeCombo="
                (value) => {
                  input.value = value
                }
              "
            /> -->
            <SingleSelect
              :disabled="input.disabled ? input.disabled : false"
              :slug="input.slug"
              :slugId="input.slugId"
              :slugDesc="input.slugDesc"
              :endpoint="input.endpoint"
              :required="input.required"
              :model="input.value"
              @onChangeCombo="
                (value) => {
                  input.value = value
                }
              "
            />
          </b-form-group>
        </div>
      </div>
    </b-card>
  </b-form>
</template>

<script>
import service from '@/services/BaseService'
import moment from 'moment'

export default {
  props: {
    fieldsInput: Array,
    item: Object,
    table: String,
    labelHeader: String,
    positionModal: String,
  },
  data() {
    return {
      form: this.item,
    }
  },
  created() {
    this.initInputs()
  },
  methods: {
    //Inicializa os campos quanto for edição
    initInputs() {
      //Edição
      if (this.item.id) {
        for (let i = 0; i < this.fieldsInput.length; i++) {
          const field = this.fieldsInput[i]
          Object.keys(this.item).map((key) => {
            if (field.slug == key) {
              field.value = this.item[key]
            }
          })
        }
      }
      //Cadastro
      else {
        for (let i = 0; i < this.fieldsInput.length; i++) {
          const field = this.fieldsInput[i]
          if (field.value == '') {
            field.value = null
          }
        }
      }
    },
    //Monta o json que será enviado na requisição com base nos fields
    setInputs() {
      this.form = {}
      this.fieldsInput.forEach((field) => {
        if (field.type == 'date') {
          this.form[field.slug] = field.value
            ? moment(field.value).format(
                field.formatDate ? field.formatDate : 'YYYY-MM-DD H:mm',
              )
            : null
        } else {
          this.form[field.slug] = field.value
        }
      })
    },
    async onSubmit() {
      this.setInputs()
      var http = new service(`default/${this.table}`)
      if (this.item.id == null) {
        this.$confirm.require({
          message: 'Deseja realmente adicionar esse item?',
          header: 'Confirmação',

          acceptClass: 'p-button-success',
          accept: async () => {
            await http
              .create(this.form)
              .then((resp) => {
                this.$toast.success('Registro Criado com Sucesso!')
                this.$emit('onChangeForm', resp.data.data)
              })
              .catch((e) => {
                this.$toast.error(e.response.data.message)
              })
          },
        })
      } else {
        this.$confirm.require({
          message: 'Deseja realmente alterar esse item?',
          header: 'Confirmação',

          acceptClass: 'p-button-success',
          accept: async () => {
            await http
              .update(this.item.id, this.form)
              .then(() => {
                this.$toast.success('Registro Atualizado com Sucesso!')
                this.$emit('onChangeForm')
              })
              .catch((e) => {
                this.$toast.error(e.response.data.message)
              })
          },
        })
      }
    },
    onChangeForm() {
      this.$emit('onChangeForm')
    },
    onChangeCloseModal() {
      this.$emit('onChangeCloseModal')
    },
  },
}
</script>
